<template>
  <div class="p-3">
    <b-row>
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(last_updated)="data">
            <template v-if="data.item.last_updated">
              <div>{{ data.item.last_updated | moment($formatDateNew) }}</div>
              <div class="time-color">
                {{ data.item.last_updated | moment("HH:mm:ss") }}
              </div>
            </template>
            <template v-else>
              <div>-</div>
            </template>
          </template>
          <template v-slot:cell(user_information)="data">
            <!-- <b-button
              v-if="data.item.user_information"
              variant="custom-primary"
              @click="showJSON(data.item.user_information)"
            >
              Preview JSON
            </b-button> -->
            <pre v-if="data.item.user_information" class="text-left px-4">
              {{ JSON.parse(data.item.user_information) }}
            </pre>
            <div v-else>-</div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <Pagination
      @handleChangeTake="changePerPage"
      :filter="filter"
      :rows="rows"
      @pagination="pagination"
    />
    <b-row class="mt-4">
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields2"
          :items="items2"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(created_time)="data">
            <div>{{ data.item.created_time | moment($formatDateNew) }}</div>
            <div class="time-color">
              {{ data.item.created_time | moment("HH:mm:ss") }}
            </div>
          </template>
          <template v-slot:cell(action)="data">
            <b-button
              variant="custom-primary"
              @click="disconnect(data.item.login_provider)"
            >
              Disconnect
            </b-button>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <Pagination
      @handleChangeTake="changePerPage2"
      :filter="filter2"
      :rows="rows2"
      @pagination="pagination2"
    />
    <b-modal
      hide-footer
      title="Preview JSON"
      v-model="modal"
      no-close-on-backdrop
      centered
    >
      <pre>{{ previewJson }}</pre>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        { key: "channel_name", label: "Channel Name" },
        { key: "last_updated", label: "Date / Time" },
        { key: "user_information", label: "Information", class: "w-5" },
      ],
      fields2: [
        { key: "login_provider", label: "Login Provider" },
        { key: "user_guid", label: "User ID" },
        { key: "created_time", label: "Date / Time" },
        { key: "action", label: "Action" },
      ],
      items: [],
      items2: [],
      isBusy: false,
      filter: {
        page: 1,
        take: 5,
        user_guid: this.$route.params.id,
        PageNumber: 1,
        RecordPerPage: 5,
      },
      filter2: {
        page: 1,
        take: 5,
        user_guid: this.$route.params.id,
        PageNumber: 1,
        RecordPerPage: 5,
      },
      rows: 0,
      rows2: 0,
      modal: false,
      previewJson: "",
    };
  },
  created() {
    this.getAll();
  },
  methods: {
    async getData() {
      const resp = await this.axios.post(
        "/Customer/connect_channel",
        this.filter
      );
      this.items = resp.data.detail.connect_channel;
      this.rows = resp.data.detail.total;
    },
    async getData2() {
      const resp = await this.axios.post("/Customer/user_login", this.filter2);
      this.items2 = resp.data.detail.user_login;
      this.rows2 = resp.data.detail.total;
    },
    getAll() {
      this.getData();
      this.getData2();
    },
    changePerPage(val) {
      this.filter.page = 1;
      this.filter.PageNumber = 1;
      this.filter.take = val;
      this.filter.RecordPerPage = val;
      this.getData();
    },
    pagination(val) {
      this.filter.page = val;
      this.filter.PageNumber = val;
      this.getData();
    },
    changePerPage2(val) {
      this.filter2.page = 1;
      this.filter2.PageNumber = 1;
      this.filter2.take = val;
      this.filter2.RecordPerPage = val;
      this.getData2();
    },
    pagination2(val) {
      this.filter2.page = val;
      this.filter2.PageNumber = val;
      this.getData2();
    },
    async disconnect(provider) {
      let payload = {
        user_guid: this.filter2.user_guid,
        login_provider: provider,
      };
      const resp = await this.axios.post(`/Customer/disconnect_login`, payload);
      if (resp.data.result) {
        this.getAll();
        this.successAlert();
      } else {
        this.errorAlert(resp.data.message);
      }
    },
    showJSON(json) {
      this.previewJson = JSON.parse(json);
      this.modal = true;
    },
  },
};
</script>

<style></style>
