<template>
  <b-modal v-model="isShow" title="Delete Customer Account">
    <b-form-group label="Select Delete Mothod" v-slot="{ ariaDescribedby }">
      <b-form-radio
        v-model="selected"
        :aria-describedby="ariaDescribedby"
        name="some-radios"
        :value="0"
        >Delete Account and move data to anonymous user</b-form-radio
      >
      <b-form-radio
        v-model="selected"
        :aria-describedby="ariaDescribedby"
        name="some-radios"
        :value="1"
        >Delete Account and all data permanently</b-form-radio
      >
    </b-form-group>
    <template #modal-footer>
      <b-row>
        <b-col
          ><b-button @click="hide" class="btn-cancel"> Cancel </b-button></b-col
        >
        <b-col
          ><b-button class="btn-confirm" @click="$emit('deleteUser', selected)">
            Confirm
          </b-button></b-col
        >
      </b-row>
    </template>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      selected: 0,
    };
  },
  methods: {
    show() {
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    },
  },
};
</script>

<style></style>
