<template>
  <b-sidebar
    id="sidebar-profile-edit"
    ref="sidebarProfileEdit"
    v-model="isShowSidebar"
    aria-labelledby="sidebar-action-header"
    right
    backdrop
    shadow
    no-header
    width="80vw"
  >
    <b-container class="no-gutters bg-white">
      <b-row class="header-filter">
        <b-col class="text-filter"> Filter </b-col>
        <b-col class="text-right">
          <button class="btn-clear" @click.prevent="clearFilter">
            x <span class="text-under">Clear Filter</span>
          </button>
        </b-col>
      </b-row>
      <div class="py-3">
        <b-row v-for="(field, index) in fields" :key="index">
          <b-col md="6">
            <div v-if="field.field_type_id == 1" class="mb-3">
              <FieldTextInput :field="field" />
            </div>

            <div v-else-if="field.field_type_id == 2">
              <FieldCheckbox :field="field" :index="index" />
            </div>
            <div v-else-if="field.field_type_id == 3">
              <FieldRadio :field="field" :index="index" />
            </div>
            <div v-else-if="field.field_type_id == 4" class="mb-3">
              <FieldDropdown :field="field" />
            </div>
            <div v-else-if="field.field_type_id == 5" class="mb-3">
              <FieldDateTime :field="field" />
            </div>
            <div v-else-if="field.field_type_id == 6" class="mb -3">
              <FieldBranch :field="field" />
            </div>
            <div v-else-if="field.field_type_id == 7" class="mb-3">
              <FieldDate :field="field" />
            </div>
            <div v-else-if="field.field_type_id == 8">
              <label
                >{{ field.field_name }}
                <span class="text-danger" v-if="field.required">*</span>
              </label>
              <UploadImage
                :indexData="index"
                @updateImageList="updateImageList"
                :dataList="field.value"
                @deleteImagesList="deleteImgList"
              />
            </div>
            <div v-else-if="field.field_type_id == 12">
              <FieldTextAreaInput :field="field" />
            </div>
          </b-col>
          <b-col md="6" class="d-md-down-none"> </b-col>
        </b-row>
      </div>
    </b-container>
    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            class="text-body rounded-pill btn-main"
          >
            Save
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import InputText from "@/components/inputs/InputText";
import InputTextArea from "@/components/inputs/InputTextArea";
import FieldTextInput from "./FieldTextInput.vue";
import FieldCheckbox from "./FieldCheckbox.vue";
import FieldRadio from "./FieldRadio.vue";
import FieldDropdown from "./FieldDropdown.vue";
import FieldDateTime from "./FieldDateTime.vue";
import FieldBranch from "./FieldBranch.vue";
import FieldDate from "./FieldDate.vue";
import FieldTextAreaInput from "./FieldTextAreaInput.vue";
import UploadImage from "./UploadImage.vue";
import ModalAlertError from "@/components/modal/ModalAlertError";
export default {
  components: {
    OtherLoading,
    InputText,
    InputTextArea,
    FieldTextInput,
    FieldCheckbox,
    FieldRadio,
    FieldDropdown,
    FieldDateTime,
    FieldBranch,
    FieldDate,
    UploadImage,
    FieldTextAreaInput,
    ModalAlertError,
  },

  data() {
    return {
      isShowSidebar: false,
      isLoading: true,
      image: "",
      acceptType: "image/jpeg, image/png",
      isLoadingImage: false,
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      isLoadingImage: false,
      isDisable: false,
      prefixOption: [],
      genderChoice: [],
      id: 0,
      custom_object_id: 0,
      fields: [],
    };
  },
  mounted() {},
  methods: {
    show(id) {
      this.id = id;
      this.setFields();

      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    async setFields() {
      this.isLoading = true;
      const resp = await this.axios(
        `/CustomObject/answer_by_answer_id/${this.id}`
      );

      this.custom_object_id = resp.data.detail.custom_object_id;
      for (const fields of resp.data.detail.fields) {
        let value = resp.data.detail.answer_datas.datas.find(
          (el) => el.field_id == fields.field_id
        );

        if (fields.field_type_id == 2) {
          fields.value = value
            ? value.answer_detail.map((el) => el.value_answer)
            : [];
        } else {
          fields.value = value
            ? value.answer_detail.map((el) => el.value_answer).join(",")
            : "";
        }
      }
      this.fields = resp.data.detail.fields;
      this.isLoading = false;
    },
    deleteImgList(index) {
      this.fields[index].value = "";
    },
    updateImageList(list) {
      this.fields[list.index].value = list.value;
    },
    async submitAction() {
      this.$bus.$emit("showLoading");
      let payload = {
        custom_object_id: this.custom_object_id,
        answers: [
          {
            deleted: 0,
            id: parseInt(this.id) || 0,
            field_answer: [],
          },
        ],
      };

      for (const field of this.fields) {
        let choice = null;
        if (field.field_choices.length > 0) {
          choice = field.field_choices.find((el) => el.name == field.value);
        }

        if (field.value && typeof field.value == "object") {
          if (field.value.length > 0) {
            for (const a of field.value) {
              const { id } = field.field_choices.find(
                (choice) => choice.name == a
              );
              payload.answers[0].field_answer.push({
                custom_object_field_id: field.field_id,
                value: a || "",
                id: 0,
                custom_object_choice_id: id || 0,
              });
            }
          }
        } else {
          payload.answers[0].field_answer.push({
            custom_object_field_id: field.field_id,
            value: field.value || "",
            id: 0,
            custom_object_choice_id: choice ? choice.id : 0,
          });
        }
      }

      const resp = await this.axios.post(
        `/CustomObject/modify_custom_object_answer`,
        payload
      );
      this.$bus.$emit("hideLoading");
      if (resp.data.result) {
        this.$emit("getData", true);
        this.successAlert();
        this.hide();
      } else {
        this.errorAlert(resp.data.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .b-sidebar {
  width: 400px;
}
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
  font-size: 17px;
}
.panel-bg-file-img {
  width: 50%;
  padding-bottom: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  margin-bottom: 15px;
  position: relative;
}

::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}

.btn-clear {
  background-color: transparent;
  border: none;
}
</style>
