<template>
  <b-modal
    v-model="isShow"
    size="lg"
    centered
    title="Documents"
    hide-header-close
    hide-footer
  >
    <template>
      <b-row class="flex-column row-gap-xl">
        <template v-for="(document, index) of documentList">
          <b-col cols="12" :key="'name-' + document.id + index">
            <div class="text-black mb-2">
              {{ document.name }}
            </div>

            <InputUploadFile
              id="id-card-image"
              :label="document.document_name"
              :img="document.path"
              v-model="document.path"
              :ImageName="document.file_name"
              :dateTime="document.created_time"
              v-if="isShow"
              @upload="saveData"
              @setFileName="(val) => (document.file_name = val)"
              @deleteImage="deleteImage(document, index)"
          /></b-col>
        </template>
        <b-col cols="12">
          <b-button
            variant="custom-outline-primary"
            @click.prevent="addOtherDocument"
            class="w-100"
            :disabled="disabledField"
          >
            <font-awesome-icon icon="plus-circle" class="mr-2" /> Add Other
            Document
          </b-button>
        </b-col>
      </b-row>

      <b-row class="justify-content-center mt-3">
        <b-col cols="4">
          <b-button
            variant="custom-primary"
            @click.prevent="isShow = false"
            class="w-100"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </template>

    <!-- </template> -->
  </b-modal>
</template>

<script>
import InputUploadFile from "@/components/inputs/InputUploadFile";

export default {
  components: { InputUploadFile },
  props: {
    form: {
      required: false,
    },
  },
  data() {
    return {
      isShow: false,
      documentList: [],
      list: [],
      listNoRegister: [],
    };
  },
  computed: {
    disabledField() {
      let path = this.documentList
        .filter((el) => el.document_type_id == 1)
        .map((el) => el.path);
      return !path.every((el) => el != "");
    },
  },
  methods: {
    deleteImage(document, index) {
      if (
        document.document_type_id == 1 &&
        this.documentList.filter((el) => el.document_type_id == 1).length > 1
      )
        this.documentList.splice(index, 1);
      this.saveData();
    },
    async show() {
      this.$bus.$emit("showLoading");
      await this.setData();
      this.$bus.$emit("hideLoading");
      this.isShow = true;
    },
    async setData() {
      await this.getList();
      let document = this.form.documents.map((el) => {
        let value = this.list.find((e) => el.document_type_id == e.id);
        return {
          id: el.id ? el.id : 0,
          document_type_id: el.document_type_id,
          document_name: el.name,
          name: el.name,
          path: el.path,
          file_name: el.file_name || "",
          user_guid: this.$route.params.id,
          created_time: value ? value.created_time : "",
          sort_order: el.sort_order,
        };
      });

      if (document.length == 0) {
        this.documentList = this.list.map((el) => {
          return {
            id: 0,
            document_type_id: el.id,
            document_name: el.name,
            name: el.name,
            path: "",
            file_name: "",
            user_guid: this.$route.params.id,
            created_time: "",
            sort_order: el.sort_order,
          };
        });
      } else {
        let cloneDocument = [];
        for (const d of document) {
          if (d.document_type_id == 1) {
            if (d.path) {
              cloneDocument.push(d);
            } else if (!cloneDocument.find((el) => el.document_type_id == 1))
              cloneDocument.push(d);
          } else {
            cloneDocument.push(d);
          }
        }
        for (const a of this.list) {
          let b = cloneDocument.findIndex((el) => el.document_type_id == a.id);
          if (b == -1)
            cloneDocument.push({
              id: 0,
              document_type_id: a.id,
              document_name: a.name,
              name: a.name,
              path: "",
              user_guid: this.$route.params.id,
              created_time: "",
              file_name: "",
              sort_order: a.sort_order,
            });
          else cloneDocument[b].sort_order = a.sort_order;
        }

        this.documentList = cloneDocument.sort(
          (a, b) => a.sort_order - b.sort_order
        );
      }
    },
    async getList() {
      const register = await this.axios(`setting/GetDocumentSetting`);
      let $noRegister = register.data.detail.filter(
        (el) => el.is_not_register == 1
      );
      let $register = register.data.detail.filter((el) => el.is_register == 1);

      if (this.form.company_information == "ไม่จดทะเบียน")
        this.list = $noRegister.sort((a, b) => a.sort_order - b.sort_order);
      else this.list = $register.sort((a, b) => a.sort_order - b.sort_order);
    },
    async saveData() {
      try {
        this.$bus.$emit("showLoading");

        this.form.documents = this.documentList.map((el) => {
          return { ...el, created_time: el.created_time || this.$moment() };
        });
        const result = await this.axios.post(
          "customer/EditDocument",
          this.form.documents
        );

        const data = result.data;
        if (data.result === 1) {
          await this.$emit("getData");
          this.successAlert().then(() => this.setData());
        } else {
          this.warningAlert(data.message);
        }
        this.$bus.$emit("hideLoading");
      } catch (error) {
        this.$bus.$emit("hideLoading");
        this.errorAlert(error.message);
      }
    },
    addOtherDocument() {
      let other = this.documentList.find((el) => el.document_type_id == 1);
      if (other) {
        let cloneOther = JSON.parse(JSON.stringify(other));
        cloneOther.id = 0;
        cloneOther.path = "";
        this.documentList.push(cloneOther);
      }
    },
  },
};
</script>

<style lang="scss"></style>
