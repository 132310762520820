<template>
  <div>
    <div class="mb-2"><b>Notes</b></div>
    <div class="bg-note">
      <b-row>
        <b-col>
          <div class="detail-note">
            <span>
              <font-awesome-icon icon="clock" class="icon-bd" />
              <span class="ml-2 color-no-edit">{{
                $moment(noteList.last_updated).format("DD/MM/YYYY HH:mm:ss")
              }}</span>
            </span>
          </div>
        </b-col>
        <b-col class="text-right">
          <div class="color-primary m-2 pointer" v-if="edit" @click="editNote">
            <font-awesome-icon icon="pencil-alt" size="sm" />
            <!-- <span class="ml-1 edit-btn">Edit</span> -->
          </div>
          <div class="color-primary m-2 pointer" v-if="save" @click="saveNote">
            <font-awesome-icon icon="save" size="sm" />
            <span class="ml-1 edit-btn">Save</span>
          </div>
        </b-col>
      </b-row>
      <div>
        <div v-if="edit">
          <div class="p-3 color-no-edit">
            <b-form-textarea
              id="textarea"
              v-model="noteList.note"
              placeholder="Add item..."
              rows="3"
              max-rows="6"
              readonly
            ></b-form-textarea>
          </div>
        </div>
        <div class="p-3" v-if="save">
          <b-form-textarea
            id="textarea"
            v-model="noteList.note"
            placeholder="Add item..."
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    noteList: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      edit: true,
      save: false,
    };
  },
  methods: {
    editNote() {
      this.save = true;
      this.edit = false;
    },
    saveNote() {
      this.$emit("updateNote", this.noteList.note);
      this.save = false;
      this.edit = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-note {
  background-color: #e9e9e9;
  border-radius: 5px;
}
.edit-btn {
  text-decoration: underline;
}
.color-edit-btn {
  color: #0098f7;
  padding: 10px;
}
.color-no-edit {
  color: #000;
}
::v-deep .form-control:disabled,
.form-control[readonly] {
  background-color: #e9e9e9;
  opacity: 1;
  border: none;
  color: #000;
  overflow-y: hidden !important;
}
.icon-bd {
  color: var(--primary-color);
}
.detail-note {
  padding: 10px;
}
</style>
