<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <template v-else>
      <div class="answer-container">
        <div v-for="ans of data.answer_datas" :key="ans.id" class="answer-row">
          <!-- <pre>{{ ans }}</pre> -->
          <b-row class="no-wrap" no-gutters>
            <div
              class="answer-detail"
              v-for="fields of ans.datas"
              :key="fields.answer_id + '-' + fields.field_id"
            >
              <div class="answer-title">{{ fields.field_name }}</div>
              <div
                v-if="
                  fields.answer_detail[0].value_answer == '' ||
                  fields.answer_detail[0].value_answer == null
                "
              >
                -
              </div>
              <div v-else-if="fields.field_type_id == 8">
                <b-img
                  :src="fields.answer_detail[0].value_answer"
                  class="answer-image"
                ></b-img>
              </div>
              <div v-else-if="fields.field_type_id == 7">
                {{
                  $moment(fields.answer_detail[0].value_answer).format(
                    "DD MMM YYYY"
                  )
                }}
              </div>
              <div v-else-if="fields.field_type_id == 5">
                {{
                  $moment(fields.answer_detail[0].value_answer).format(
                    "DD MMM YYYY (HH:mm)"
                  )
                }}
              </div>
              <div v-else>
                {{
                  fields.answer_detail.map((el) => el.value_answer).join(",")
                }}
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-center">
              <font-awesome-icon
                icon="pencil-alt"
                class="text-warning mr-2 cursor-pointer"
                @click="showSidebar(ans.answer_id)"
              />
              <font-awesome-icon
                icon="trash-alt"
                class="color-primary cursor-pointer"
                @click="deleteAnswer(ans.answer_id)"
              />
            </div>
          </b-row>
        </div>
      </div>
      <Pagination
        @handleChangeTake="handleChangeTake"
        @pagination="pagination"
        :filter="filter"
        :rows="rows"
      />
    </template>

    <SideBarEdit ref="sideBarEdit" :detail="answer_field" @getData="getData" />
  </div>
</template>

<script>
import SideBarEdit from "./sidebarEditCustomObject";
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  components: { SideBarEdit, OtherLoading },
  data() {
    return {
      data: [],
      isLoading: true,
      filter: {
        custom_object_id: 0, // must have na !!!
        search: "",
        user_guid: this.$route.params.id, // หน้า customer detail ใส่ user_guid มาด้วยนะ
        date_from: "",
        date_to: "",
        page: 1,
        page_size: 5,
        take: 5,
      },
      rows: 0,
      answer_field: [],
    };
  },
  props: { id: { required: true } },
  methods: {
    async getData(fetching) {
      try {
        if (fetching || this.data.length == 0) {
          this.filter.page_size = this.filter.take;
          this.isLoading = true;
          this.filter.custom_object_id = this.id;
          const resp = await this.axios.post(
            `/CustomObject/answer_by_custom_object_id`,
            this.filter
          );

          this.data = resp.data.detail;
          this.rows = resp.data.detail.total;
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
      }
    },

    showSidebar(id) {
      this.$refs.sideBarEdit.show(id);
    },
    pagination(value) {
      this.filter.page = value;
      this.getData(true);
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.page_size = value;
      this.getData(true);
    },
    async deleteAnswer(id) {
      const resp = await this.axios.delete(`/CustomObject/delete_answer/${id}`);
      if (resp.data.result) {
        this.successAlert().then(() => this.getData(true));
      } else {
        this.errorAlert(resp.data.message);
      }
    },
  },
};
</script>

<style>
.answer-row {
  margin-bottom: 15px;
  border-bottom: 1px solid whitesmoke;
  padding-bottom: 10px;
}
.answer-image {
  border-radius: 0.75rem;
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 100px;
}
.answer-title {
  /* white-space: nowrap; */
  color: rgb(159, 159, 159);
}
.no-wrap {
  flex-wrap: unset;
}
.answer-detail {
  min-width: 120px;
  width: 20%;
  padding: 0 0.5rem;
}
.answer-container {
  width: 100%;
  overflow: auto;
}
</style>
