<template>
  <div class="mb-3">
    <label
      >{{ field.field_name }}
      <span class="text-danger" v-if="field.required">*</span></label
    >
    <b-form-select
      :class="['input-custom', { error: field.validation }]"
      :options="field.list_branch"
      v-model="field.value"
      value-field="name"
      text-field="name"
    >
      <template #first>
        <b-form-select-option :value="null" disabled
          >-- กรุณาเลือก --</b-form-select-option
        >
      </template>
    </b-form-select>
    <div v-if="field.validation">
      <span class="text-error">กรุณาเลือกข้อมูล</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "FieldBranch",
  props: {
    field: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.input-custom.error {
  border-color: red !important;
}
.text-error {
  color: #ff0000;
  font-size: 14px !important;
  font-weight: normal !important;
}
</style>
