<template>
  <div class="bg-white p-3">
    <b-table
      ref="tablecustom"
      responsive
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
    >
      <template v-slot:cell(status_name)="{ item }">
        <span :class="colorClass(item)">{{ item.status_name }}</span>
      </template>
      <template v-slot:cell(booking_date)="{ item }">
        <div>{{ item.booking_date | moment($formatDateNew) }}</div>
        <div class="time-color">
          {{ item.booking_date | moment("HH:mm:ss") }}
        </div>
      </template>
      <template v-slot:cell(description)="{ item }">
        <div v-if="item.description.length == 0">-</div>
        <div v-else>
          <div v-for="q of item.description" :key="q.name">
            <div class="time-color">{{ q.name }}</div>
            <div>{{ q.value }}</div>
          </div>
        </div>
      </template>
      <template v-slot:cell(sale_branch)="{ item }">
        <div v-if="!item.sale_branch && !item.sale">-</div>
        <div>{{ item.sale_branch || "" }}</div>
        <div class="time-color">
          {{ item.sale || "" }}
        </div>
      </template>
      <template v-slot:cell(updated_time)="{ item }">
        <div>{{ item.updated_time | moment($formatDateNew) }}</div>
        <div class="time-color">
          {{ item.updated_time | moment("HH:mm:ss") }}
        </div>
      </template>
      <template v-slot:cell(created_time)="{ item }">
        <div>{{ item.created_time | moment($formatDateNew) }}</div>
        <div class="time-color">
          {{ item.created_time | moment("HH:mm:ss") }}
        </div>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
    </b-table>
    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :filter="filter"
      :rows="rows"
      @pagination="pagination"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isBusy: false,
      filter: {
        user_guid: this.$route.params.id,
        search: "",
        page: 1,
        take: 10,
        branch_id: 0,
        start_date: "",
        end_date: "",
      },
      toShow: this.showingTo,
      fields: [
        {
          key: "prefix_id",
          label: "ID",
        },
        {
          key: "description",
          label: "Description",
        },
        {
          key: "booking_date",
          label: "Booking Date",
        },
        {
          key: "status_name",
          label: "Status",
        },
        {
          key: "sale_branch",
          label: "Sale ID / Branch",
        },
        {
          key: "created_time",
          label: "Create Date",
        },
        {
          key: "updated_time",
          label: "Last Update",
        },
      ],
      items: [],
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList(filter) {
      let payload = this.filter;
      if (filter) {
        payload = { ...filter, ...this.filter };
      }
      this.isBusy = true;
      const res = await this.axios.post(
        `/booking/getMenulist/${this.$route.params.id}`,
        payload
      );
      this.rows = res.data.detail.count;
      this.items = res.data.detail.reponse;
      this.isBusy = false;
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getList();
    },
    pagination(value) {
      this.filter.page = value;

      this.getList();
    },
    colorClass(item) {
      if (item.status == "1") return "text-warning";
      else if (item.status == "2") return "text-success";
      else if (item.status == "3") return "text-danger";
      else return "text-secondary";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep a {
  color: #333 !important;
}
.text-invoice_no {
  color: #333;
  text-decoration: underline;
}
.status-complete {
  color: #00bd00;
}
.status-void {
  color: #f9b115;
}
.status-cancel {
  color: red;
}
.bg-log-type {
  opacity: 0.5;
  border-radius: 50px;
  padding: 0 10px;
}
.log-type-odd {
  background-color: #d79b9b;
  color: red;
}
.log-type-even {
  background-color: #9bd79b;
  color: green;
}
.datetime {
  color: #919191;
}
</style>
