<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-modal
        v-model="showModal"
        ref="modal-point"
        @show="show"
        @hidden="hide"
        centered
      >
        <template #modal-header>
          <div class="ft-black">
            {{ typePoint === 1 ? "Center Point" : "Branch Point" }}
          </div>
        </template>
        <div>
          <b-tabs fill v-model="tabIndex">
            <!-- Increase = 1 -->
            <b-tab
              title="Add Points"
              @click.prevent="$emit('tabIndexPoint', 0)"
            >
              <div class="p-3">
                <div v-if="typePoint === 2" class="mb-3">
                  <InputSelect
                    title="Select Branch"
                    name="branch"
                    isRequired
                    noPleaseSelect
                    class="mt-2"
                    :isValidate="v.branch_id.$error"
                    v-bind:options="branchList"
                    v-model="form.branch_id"
                    :v="v.branch_id"
                    valueField="id"
                    textField="name"
                    @onDataChange="selectBranch($event)"
                  />
                </div>
                <div>
                  <b-row>
                    <b-col
                      class="text-right current-point"
                      v-if="typePoint === 1"
                      >Current Point
                      {{ point.center_point | numeral("0,0") }} Point</b-col
                    >
                    <b-col
                      v-if="typePoint === 2"
                      class="text-right current-point"
                      >Current Point
                      {{ pointBranch | numeral("0,0") }} Point</b-col
                    >
                  </b-row>
                  <InputText
                    textFloat="Add Points"
                    placeholder="Add Points"
                    type="number"
                    name="add_point"
                    v-model="form.addPoint"
                    :isValidate="v.addPoint.$error"
                    :v="v.addPoint"
                    @input="changePoint"
                    :aria-disabled="typePoint == 2 && !form.branch_id"
                  />
                </div>
                <div>
                  <InputText
                    v-if="typePoint === 1"
                    textFloat="All Points"
                    placeholder="All Points"
                    type="number"
                    v-model="totalPoint"
                    name="total_point"
                    :disabled="true"
                  />
                  <InputText
                    v-if="typePoint === 2"
                    textFloat="All Points"
                    placeholder="All Points"
                    v-model="totalPoint"
                    type="number"
                    name="total_point"
                    :disabled="true"
                  />
                </div>
                <div>
                  <InputTextArea
                    :rows="4"
                    textFloat="Note"
                    placeholder="Note"
                    id="note1"
                    v-model="form.note"
                  />
                </div>
              </div>
            </b-tab>
            <!-- Decrease = 2  -->
            <b-tab
              title="Reduce Points"
              @click.prevent="$emit('tabIndexPoint', 1)"
            >
              <div class="p-3">
                <div v-if="typePoint === 2" class="mb-3">
                  <InputSelect
                    title="Select Branch"
                    name="branch"
                    isRequired
                    class="mt-2"
                    noPleaseSelect
                    v-bind:options="branchList"
                    v-model="form.branch_id"
                    :isValidate="v.branch_id.$error"
                    :v="v.branch_id"
                    valueField="id"
                    textField="name"
                    @onDataChange="selectBranch($event)"
                  />
                </div>
                <div>
                  <b-row>
                    <b-col
                      class="text-right current-point"
                      v-if="typePoint === 1"
                      >Current Point
                      {{ point.center_point | numeral("0,0") }} Point</b-col
                    >
                    <b-col
                      v-if="typePoint === 2"
                      class="text-right current-point"
                      >Current Point
                      {{ pointBranch | numeral("0,0") }} Point</b-col
                    >
                  </b-row>
                  <InputText
                    textFloat="Reduce Points"
                    placeholder="Reduce Points"
                    type="number"
                    name="delete_point"
                    v-model="form.deletePoint"
                    :isValidate="v.deletePoint.$error"
                    :v="v.deletePoint"
                    @input="changePoint"
                  />
                </div>
                <div>
                  <InputText
                    v-if="typePoint === 1"
                    textFloat="All Points"
                    placeholder="All Points"
                    v-model="totalDeletePoint"
                    type="number"
                    name="total_point"
                    :disabled="true"
                  />
                  <InputText
                    v-if="typePoint === 2"
                    textFloat="All Points"
                    placeholder="All Points"
                    v-model="totalPoint"
                    type="number"
                    name="total_point"
                    :disabled="true"
                  />
                </div>
                <div>
                  <InputTextArea
                    :rows="4"
                    textFloat="Note"
                    placeholder="Note"
                    id="note2"
                    v-model="form.note"
                  />
                </div>
              </div>
            </b-tab>
            <!-- Adjust = 3 -->
            <b-tab
              title="Edit Point"
              @click.prevent="$emit('tabIndexPoint', 2)"
            >
              <div class="p-3">
                <div v-if="typePoint === 2" class="mb-3">
                  <InputSelect
                    title="Select Branch"
                    name="branch"
                    isRequired
                    class="mt-2"
                    v-bind:options="branchList"
                    v-model="form.branch_id"
                    valueField="id"
                    noPleaseSelect
                    :isValidate="v.branch_id.$error"
                    :v="v.branch_id"
                    textField="name"
                    @onDataChange="selectBranch($event)"
                  >
                    <!-- <template v-slot:option-first>
                      <b-form-select-option :value="null" disabled>
                        Please select value.
                      </b-form-select-option>
                    </template> -->
                  </InputSelect>
                </div>
                <div>
                  <b-row>
                    <b-col
                      v-if="typePoint === 1"
                      class="text-right current-point"
                      >Current Point
                      {{ point.center_point | numeral("0,0") }} Point</b-col
                    >
                    <b-col
                      v-if="typePoint === 2"
                      class="text-right current-point"
                      >Current Point
                      {{ pointBranch | numeral("0,0") }} Point</b-col
                    >
                  </b-row>
                  <InputText
                    textFloat="Edit Point"
                    placeholder="Edit Point"
                    v-model="form.editPoint"
                    :isValidate="v.editPoint.$error"
                    @input="changePoint"
                    :v="v.editPoint"
                    type="number"
                    name="edit_point"
                  />
                </div>
                <div>
                  <InputTextArea
                    :rows="4"
                    textFloat="Note"
                    placeholder="Note"
                    id="note3"
                    v-model="form.note"
                  />
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
        <template #modal-footer>
          <b-row>
            <b-col
              ><b-button @click="hide" class="btn-cancel">
                Cancel
              </b-button></b-col
            >
            <b-col
              ><b-button
                :disabled="loading"
                class="btn-confirm"
                @click.prevent="confirmPoint()"
              >
                {{ loading ? "Loading" : "Save" }}
              </b-button></b-col
            >
          </b-row>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputTextArea from "@/components/inputs/InputTextArea";
import InputSelect from "@/components/inputs/InputSelect";
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  components: {
    InputText,
    InputTextArea,
    InputSelect,
    OtherLoading,
  },
  props: {
    branchList: {
      required: true,
      type: Array,
    },
    form: {
      required: true,
      type: Object,
    },
    point: {
      required: true,
      type: Object,
    },
    v: {
      required: true,
      type: Object,
    },
    id: {
      required: true,
      type: String,
    },
    loading: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      showModal: false,
      typePoint: 0,
      tabIndex: 0,
      totalPoint: 0,
      totalDeletePoint: this.point.center_point,
      totalEditPoint: 0,
      pointBranch: 0,
      isLoading: this.loading,
    };
  },
  watch: {
    tabIndex(val) {
      if (val === 0) {
        this.form.update_type = 1;
      } else if (val === 1) {
        this.form.update_type = 2;
      } else if (val === 2) {
        this.form.update_type = 3;
      }
    },
    "point.branch_point": function (value) {
      return (this.totalPoint = Number(value));
    },
    "form.addPoint": function (value) {
      if (this.typePoint === 1) {
        this.totalPoint = parseInt(this.point.center_point);
        if (this.form.addPoint) {
          this.totalPoint =
            parseInt(this.totalPoint) + parseInt(this.form.addPoint);
        } else {
          this.totalPoint = parseInt(this.point.center_point);
        }
      } else if (this.typePoint === 2) {
        this.totalPoint = parseInt(this.point.branch_point);

        if (this.form.addPoint) {
          this.totalPoint =
            parseInt(this.totalPoint) + parseInt(this.form.addPoint);
        } else {
          this.totalPoint = parseInt(this.point.branch_point);
        }
      }
    },
    "form.deletePoint": function (value) {
      if (this.typePoint === 1) {
        this.totalPoint = parseInt(this.point.center_point);
        if (this.form.deletePoint) {
          this.totalPoint =
            parseInt(this.totalPoint) - parseInt(this.form.deletePoint);
        } else {
          this.totalPoint = parseInt(this.point.center_point);
        }
      } else if (this.typePoint === 2) {
        this.totalPoint = parseInt(this.point.branch_point);

        if (this.form.deletePoint) {
          this.totalPoint =
            parseInt(this.totalPoint) - parseInt(this.form.deletePoint);
        } else {
          this.totalPoint = parseInt(this.point.branch_point);
        }
      }
    },
    point(val) {
      this.totalDeletePoint = val.center_point;
    },
  },
  methods: {
    show(val) {
      if (val === 1) {
        this.typePoint = 1;
        this.totalPoint = this.point.center_point;
      } else if (val === 2) {
        this.typePoint = 2;

        this.totalPoint = this.point.branch_point;
      } else {
        this.getPointOfBranch();
      }
      this.showModal = true;
    },
    hide() {
      this.form.addPoint = null;
      this.form.deletePoint = null;
      this.totalPoint = 0;
      this.form.branch_id = null;
      this.totalDeletePoint = 0;
      this.tabIndex = 0;
      this.pointBranch = 0;
      this.showModal = false;
      this.v.$reset();
    },
    selectBranch(evt) {
      this.form.branch_id = evt;
      this.getPointBranch();
      // this.isLoading = true;
    },
    async getPointBranch() {
      let form = {
        user_guid: this.id,
        branch_id: this.form.branch_id,
      };
      await this.$store.dispatch("getPointByBranch", form);
      const data = this.$store.state.report.statePointByBranch;
      if (data.result === 1) {
        this.pointBranch = data.detail;
      }
    },
    async getPointOfBranch() {
      const resp = await this.axios(`/branch/list/${this.id}`);
      for (const branch of this.branchList) {
        let point = resp.data.detail.find((el) => el.id == branch.id);
        branch.name = `${point.name} (${point.point})`;
      }
    },
    changePoint(value) {
      if (this.tabIndex === 0) {
        this.form.update_type = 1;
      } else if (this.tabIndex === 1) {
        this.form.update_type = 2;
      } else if (this.tabIndex === 2) {
        this.form.update_type = 3;
      }
    },
    confirmPoint() {
      if (this.typePoint === 1) {
        this.form.branch_id = 0;
      } else if (this.typePoint === 2) {
        this.v.branch_id.$touch();
        if (this.v.branch_id.$error) {
          return;
        }
      }
      if (this.tabIndex === 0) {
        this.v.addPoint.$touch();
        if (this.v.addPoint.$error) {
          return;
        }
        this.form.update_point = parseInt(this.form.addPoint);
      } else if (this.tabIndex === 1) {
        this.v.deletePoint.$touch();
        if (this.v.deletePoint.$error) {
          return;
        }
        this.form.update_point = parseInt(this.form.deletePoint);
      } else if (this.tabIndex === 2) {
        this.v.editPoint.$touch();
        if (this.v.editPoint.$error) {
          return;
        }
        this.form.update_point = parseInt(this.form.editPoint);
      }
      this.$emit("updatePoint", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  background-color: var(--theme-secondary-color);
}
.ft-black {
  color: var(--font-color);
  font-weight: 600;
  font-size: 16px;
}
::v-deep .nav.nav-tabs.nav-fill {
  border: 10px solid #c5c5c5;
}
::v-deep .modal-body {
  padding: 0;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: var(--primary-color);
  border-bottom: none;
  color: #fff !important;
}
::v-deep .modal-footer {
  display: block;
}
.btn-cancel {
  background-color: transparent;
  width: 100%;
}
.btn-confirm {
  background-color: var(--primary-color);
  color: #fff;
  width: 100%;
}
.current-point {
  color: gray;
  position: absolute;
}
</style>
