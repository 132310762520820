<template>
  <b-sidebar
    id="sidebar-activity"
    :visible="isShowSidebar"
    aria-labelledby="sidebar-activity"
    shadow
    right
    :lazy="true"
    backdrop
    :no-enforce-focus="true"
    width="80vw"
    @hidden="hide"
  >
    <template #header>
      <b-row class="header-filter no-gutters">
        <b-col class="text-filter">Filter Ticket</b-col>
        <b-col class="text-right">
          <button class="btn-clear" @click.prevent="clearFilter">
            x <span class="text-under">Clear Filter</span>
          </button>
        </b-col>
      </b-row>
    </template>
    <div class="p-3">
      <InputStatus :value="filter.status" @change="filter.status = $event" />
    </div>
    <template #footer>
      <div
        class="d-flex justify-content-between shadow align-items-center footer"
      >
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="sendForm"
            block
            variant="light"
            id="handle-search-filter"
            class="text-body rounded-pill btn-main"
          >
            Save
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import InputStatus from "@/components/ticket/activity/InputStatus";
export default {
  components: {
    InputStatus,
  },
  props: {},
  data() {
    return {
      isShowSidebar: false,
      filter: {
        status: [],
      },
    };
  },

  computed: {},
  validations() {
    return {};
  },
  created() {},
  methods: {
    show() {
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    clearFilter() {
      this.filter = { page: 1, status: [] };
      this.$emit("afterSubmit", this.filter);
      this.hide();
    },
    sendForm() {
      this.filter.page = 1;
      this.$emit("afterSubmit", this.filter);
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .b-sidebar > .b-sidebar-header {
  font-size: 16px;
  display: block;
  padding: unset;
}
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0.5rem;
}
.text-filter {
  font-weight: 600;
}

.text-filter {
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}

::v-deep .btn-main,
.btn-mains button {
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}

.footer {
  ::v-deep .rounded-pill {
    border-radius: 0px !important;
  }
  ::v-deep .btn-main,
  .btn-mains button {
    width: 100% !important;
  }
}

.text-under {
  text-decoration: underline;
}

input.search-bar::placeholder {
  text-transform: capitalize;
}
.form-group {
  margin-bottom: 0.5rem !important;
}

.dependent-form {
  background-color: var(--secondary-color);
  padding: 16px;
}

.question-card-title {
  display: flex;
  gap: 4px;
  align-items: center;
}

.type-box {
  padding: 2px 8px;
  color: var(--primary-color);
  background-color: var(--secondary-color);
  border-radius: 6px;
}

.text-light-grey {
  color: #9a9a9a;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pagination-action {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}

.w-100px {
  width: 101px !important;
  min-width: 0 !important;
}
</style>
