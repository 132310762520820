<template>
  <div class="mb-3">
    <label
      >{{ field.field_name }}
      <span class="text-danger" v-if="field.required">*</span>
      <span class="text-error" v-if="field.validation"
        >กรุณาเลือกอย่างน้อย 1 ข้อมูล</span
      ></label
    >
    <b-form-radio
      v-for="(radio, i) in field.field_choices"
      :key="i"
      :name="`radio-${index}`"
      :value="radio.name"
      v-model="field.value"
      >{{ radio.name }}</b-form-radio
    >
  </div>
</template>

<script>
export default {
  name: "FieldRadio",
  props: {
    field: {
      required: true,
      type: Object,
    },

    index: {
      required: true,
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.text-error {
  color: #ff0000;
  font-size: 14px !important;
  font-weight: normal !important;
}
</style>
